<template>
    <div class="JDeviceList">

        <div v-if="isLogin && tempList && tempList.length > 0" style="position:relative;height:auto;margin-top:10px;">
            <table class="configItemTalbe" width="99%">
                <thead>
					<tr><td>{{'name'|i18n}}</td><td>{{'desc'|i18n}}</td>
					<td>{{'服务类型'}}</td><td>{{'服务类型码'}}</td>
					<td>{{'actId'|i18n}}</td><td>{{'clientId'|i18n}}</td>
					<td>{{'id'|i18n}}</td><td>{{'updatedTime'|i18n}}</td>
                    <td>{{"Operation"|i18n}}</td></tr>
                </thead>
                <tr v-for="c in tempList" :key="'ide' + c.id">
                      <td>{{c.name}}</td>
					  <td>{{c.desc}}</td>
					  <td>{{getSrvTypeName(c.type)}}</td>
					  <td>{{c.type}}</td>
					  <td>{{c.createdBy}}</td>
					  <td>{{c.clientId}}</td> 
					  <!-- <td>{{c.deviceId}}</td> -->
					   <td>{{c.id}}</td>
					  <td>{{c.updatedTime | formatDate(2)}}</td>
                    <td>
                        <a  @click="viewDev(c)">{{"View"|i18n}}</a>&nbsp;
                        <a  @click="updateDev(c)">{{"Update"|i18n}}</a>&nbsp;
						<a  @click="deleteDev(c.id)">{{"Delete"|i18n}}</a>
						<!-- <a  @click="funList(c)">{{"Funtions"|i18n}}</a>&nbsp; -->
                    </td>
                </tr>
            </table>
        </div>

        <div v-if="isLogin && tempList && tempList.length > 0" style="position:relative;text-align:center;">
            <Page ref="pager" :total="totalNum" :page-size="queryParams.size" :current="queryParams.curPage"
                  show-elevator show-sizer show-total @on-change="curPageChange"
                  @on-page-size-change="pageSizeChange" :page-size-opts="[10, 30, 60,100]"></Page>
        </div>

		<div v-if="!isLogin">
            No permission!
        </div>
		
        <div v-if="!tempList || tempList.length == 0">
            No data!
        </div>
	
	<!-- 弹出查询页面 -->
	<div v-if="isLogin"  :style="queryDrawer.drawerBtnStyle" class="drawerJinvokeBtnStatu" @mouseenter="openQueryDrawer()"></div>
	
	<Drawer ref="deviceInfoDrawer"  v-model="deviceDrawer.drawerStatus" :closable="false" placement="right" :transfer="true"
	         :styles="deviceDrawer.drawerBtnStyle" :draggable="true" :scrollable="true" width="40" :mask-closable="false" :mask="true">
		<el-row>
			<el-button size="mini" @click="deviceDrawer.drawerStatus = false">取消</el-button>
			<el-button :disabled="model==3" size="mini" type="primary" @click="doAddOrUpdateParam">保存</el-button>
		</el-row>
		
		<el-row>
			<el-col :span="6">{{"模板名称"}}</el-col>
			<el-col><el-input v-model="temp.name" :disabled="model==3" /></el-col>
		</el-row>
		<el-row>
			<el-col :span="6">{{"模板类型"}}</el-col>
			<el-col>
				<el-select style="width:100%" v-model="temp.type" :disabled="model==3">
					<el-option v-for="ty in typesMap" :key="ty.attr0" :value="ty.attr0" :label="ty.name"></el-option>
				</el-select>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="6">{{"标签片段"}}</el-col>
			<el-col>
				<el-input :disabled="model==3"
				  type="textarea"
				  :rows="5"
				  placeholder="请输入内容"
				  v-model="temp.template">
				</el-input>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="6">{{"模板描述"}}</el-col>
			<el-col><el-input v-model="temp.desc" :disabled="model==3" /></el-col>
		</el-row>
		<el-row>
			<el-col :span="6">{{"ClientId"|i18n}}</el-col>
			<el-col>
				<el-select style="width:100%" v-model="temp.clientId" :disabled="model==3" placeholder="请选择">
					<el-option v-for="o in $jr.auth.getClients()" :key="'c_'+o" :value="o" :label="o"></el-option>
				</el-select>
			</el-col>
		 </el-row>
		<el-row>
			<el-col :span="6">{{"ID"|i18n}}</el-col>
			<el-col><el-input v-model="temp.id" :disabled="true" /></el-col>
		</el-row>

	</Drawer>
	
	<!-- 数据查询开始-->
	<Drawer v-if="isLogin"   v-model="queryDrawer.drawerStatus" :closable="false" placement="left" :transfer="true"
	         :draggable="true" :scrollable="true" width="50">
	    <table id="queryTable">	        	
	        <tr>
	            <td>ActId</td><td> <Input  v-model="queryParams.ps.actId"/></td>
	            <td>ClientId</td><td> <Input  v-model="queryParams.ps.clientId"/></td>
	        </tr>
	        <tr>
	            <td>name</td><td> <Input  v-model="queryParams.ps.name"/></td>
	        	<td>desc</td><td> <Input  v-model="queryParams.ps.desc"/></td>
	        </tr>
			
			<tr>
			    <td>deviceId</td><td> <Input  v-model="queryParams.ps.deviceId"/></td>
				<td></td><td></td>
			</tr>
			
	        <tr>
	            <td><i-button @click="doQuery()">{{"Query"|i18n}}</i-button></td><td></td>
	        </tr>
	    </table>
	</Drawer><!-- 数据查询结束-->
	
    </div>
</template>

<script>

 const cid = 'DeviceSrvItemTemplate'
 
export default {
        name: cid,
        components: {},
        data() {
            return {
                errorMsg:'',
                isLogin:false,
                tempList: [],
				
				myDeviceMap:{},//
				typesMap : [], //[{key:1, val: "继电器"}, {key:2, val:"磁保持继电器"}, {key:3, val:"MQ传感器"}] ,
				
				queryParams:{size:10,curPage:1,ps:{}},
				totalNum:0,

				model:3,
                selProductId:"",
                temp : {},
				
				prdMap:{},
				
                deviceDrawer: {
                    drawerStatus : false,
                    drawerBtnStyle : {left:'0px',zindex:1000},
                },
				
				queryDrawer: {
				    drawerStatus:false,
				    drawerBtnStyle:{left:'0px',zindex:1000},
				},
				
            }
        },

        methods: {
			getSrvTypeName(type){
				let t = this.typesMap.find(e=>e.attr0==type)
				return t?t.name:""
			},
			doQuery() {
				this.queryParams.curPage = 1
			    this.refresh()
			},
			
			openQueryDrawer() {
			    this.queryDrawer.drawerStatus = true
			    this.queryDrawer.drawerBtnStyle.zindex = 10000
			    this.queryDrawer.drawerBtnStyle.left = '0px'
			},
			
			viewDev(c){
				this.selProductId = c.productId
				this.model = 3
				this.temp = c
				this.errorMsg = ''
				this.deviceDrawer.drawerStatus = true
			},
			
			addDev() {
				this.selProductId = ""
				this.model = 2
				this.temp = {type:1}
				this.errorMsg = ''
				this.deviceDrawer.drawerStatus = true
			},
			
			updateDev(c){
				this.selProductId = c.productId
				this.model = 1
				this.temp = c
				this.errorMsg = ''
				this.deviceDrawer.drawerStatus = true
			},

			doAddOrUpdateParam() {
				if (!this.checkParam(this.temp)) {
					return
				}
			
				this.temp.productId = parseInt(this.selProductId)
				
				console.log(this.temp)
				if (this.model == 1) {
					//update
					this.$jr.rpc.invokeByCode(1544661884, [this.temp])
						.then((resp) => {
							if (resp.code == 0) {
								this.deviceDrawer.drawerStatus = false;
								this.$notify.info({title: '提示',message:"更新成功"});
							} else {
								this.$notify.error({title: '错误',message: resp.msg || "未知错误"});
							}
						}).catch((err) => {
							this.$notify.error({title: '错误',message: err});
						});
				} else if(this.model == 2) {
					//add
					this.$jr.rpc.invokeByCode(122996352, [this.temp])
						.then((resp) => {
							if (resp.code == 0 && resp.data) {
								this.deviceDrawer.drawerStatus = false
								this.refresh()
								this.$notify.info({title: '提示',message:"保存存成功"});
							} else {
								this.$notify.error({
									title: '错误',
									message: resp.msg || "未知错误"
								});
							}
						}).catch((err) => {
							this.$notify.error({
								title: '错误',
								message: err
							});
						});
				}
			},
			
			checkParam(d) {
				if(!d.type) {
					this.$notify.error({
						title: '错误',
						message: "缺少模板所属分类"
					});
					return false
				}
				
				if(!d.name) {
					this.$notify.error({
						title: '错误',
						message: "模板名称不能为空"
					});
					return false
				}
				
				if(!d.template) {
					this.$notify.error({
						title: '错误',
						message: "模板数据不能为空"
					});
					return false
				}
				
				return true
			},
			
			deleteDev(defId){
				//delFunDef
				this.$jr.rpc.invokeByCode(120072050, [defId])
					.then((resp)=>{
						if(resp.code == 0){
						   this.refresh()
						} else {
						   this.$notify.error({
								title: '错误',
								message: resp.msg
							});
						}
					}).catch((err)=>{
						this.$notify.error({
							title: '错误',
							message: resp.msg
						});
				});
			},
			
            curPageChange(curPage){
				this.queryParams.curPage = curPage
                this.refresh();
            },

            pageSizeChange(pageSize){
				this.queryParams.size = pageSize
				this.queryParams.curPage = 1
                this.refresh();
            },

            refresh() {
                let self = this;
                this.isLogin = this.$jr.auth.isLogin();
                if(this.isLogin) {
                    let params = this.getQueryConditions();
					
                    let self = this;
					//deviceFunDefs
                    this.$jr.rpc.invokeByCode(1934228742, [params])
                        .then((resp)=>{
                            if(resp.code == 0){
                                self.tempList = resp.data;
								self.tempList.forEach(e=>{
									if(!e.productId) {
										e.productId = ""
									}else {
										e.productId = e.productId + ""
									}
								})
								console.log( self.tempList)
                                self.totalNum = resp.total;
                                //self.queryParams.curPage = 1;
                            } else {
								this.$notify.error({
									title: '错误',
									message: resp.msg
								});
                            }
                        }).catch((err)=>{
						   this.$notify.error({
								title: '错误',
								message: JSON.stringify(err)
							});
                    });
                }else {
                    self.tempList = [];
                }
            },

            getQueryConditions() {
                return this.queryParams;
            },
        },

        async mounted () {
            this.$el.style.minHeight=(document.body.clientHeight-67)+'px';
            this.$jr.auth.addActListener(this.refresh);
			
			let rs = await this.$jr.api.getDataType("device_srv_type")
			console.log("device_srv_type",rs)
			if(rs.code == 0) {
				this.typesMap = rs.data
				this.typesMap.forEach(e=>e.attr0=parseInt(e.attr0))
			}
			
            this.refresh();
            let self = this;
            this.$bus.$emit("editorOpen",
                {"editorId":cid, "menus":[
                    {name:"REFRESH",label:"Refresh",icon:"ios-cog",call:self.refresh},
					{name:"Add",label:"Add",icon:"ios-cog",call:self.addDev},
					]
                });

            let ec = function() {
                this.$jr.auth.removeActListener(cid);
                this.$off('editorClosed',ec);
            }

            this.$bus.$on('editorClosed',ec);
        },

        beforeDestroy() {
            this.$jr.auth.removeActListener(cid);
        },

    }
</script>

<style>
    .JDeviceList{
        min-height: 500px;
    }

	.descCol{
		overflow: hidden;
		text-overflow: ellipsis;
		flex-wrap: nowrap;
	}
	
	.title {
		font-weight: bold;
	}
	
	
</style>